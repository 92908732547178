import { queryCMS } from './cmsapi.js'

global.cmsApi = {

    getCmsItem: function (code) {
        var items = global.cmsDefaultData.filter(item => item.code === code);
        if (items.length > 0) {
            return items[0];
        } else {
            return null;
        }
    },

    getCmsData: function (code, completion) {
        var cmsItem = this.getCmsItem(code)
        if (cmsItem === null) return;
        var local = localStorage.getItem(code);
        if (local) {
            var localData = JSON.parse(local);
            completion(localData);
            console.log("Local")
        } else {
            completion(cmsItem.data.data);
            console.log("Project")
        }
        queryCMS(cmsItem.query).then((data) => {
            try {
                completion(data);
                console.log("Cloud")
                localStorage.setItem(code, JSON.stringify(data));
            } catch (error) {
                //loadDefaultImages();
            }
        }, (error) => {
            //loadDefaultImages();
        });
    },

    getLandingCms: function (completion) {
        var code = "CmsLanding"
        this.getCmsData(code, completion);
    },

}

global.cmsDefaultData = [
    {
        code: "CmsLanding",
        query:
            `
            findLandingContent(id: "e55588bf-68cf-49c3-a379-f3a7fd172980") {
                flatData {
                  planes {
                    flatData {
                      nombre
                      precioMes
                      caracteristicas {
                        titulo
                      }
                      color
                      destacado
                    }
                  }
                  videos {
                    flatData {
                      titulo
                      descripcion
                      modulo
                      url
                      versionApp
                    }
                  }
                }
              }
        `,
        data:
        {
            "data": {
                "findLandingContent": {
                    "flatData": {
                        "planes": [
                            {
                                "flatData": {
                                    "nombre": "Plan Emprendedor",
                                    "precioMes": 19900,
                                    "caracteristicas": [
                                        {
                                            "titulo": "Solo Facturación"
                                        },
                                        {
                                            "titulo": "Hasta 10 facturas electrónicas"
                                        },
                                        {
                                            "titulo": "Hasta 5 millones de ingresos"
                                        },
                                        {
                                            "titulo": "1 Usuario"
                                        },
                                        {
                                            "titulo": "1 Empresa"
                                        }
                                    ],
                                    "color": "#fe6363",
                                    "destacado": null
                                }
                            },
                            {
                                "flatData": {
                                    "nombre": "Plan Básico",
                                    "precioMes": 45900,
                                    "caracteristicas": [
                                        {
                                            "titulo": "Facturación y Cotizaciones"
                                        },
                                        {
                                            "titulo": "Hasta 50 facturas electrónicas"
                                        },
                                        {
                                            "titulo": "Hasta 30 millones de ingresos"
                                        },
                                        {
                                            "titulo": "Hasta 2 usuarios"
                                        },
                                        {
                                            "titulo": "1 Empresa"
                                        }
                                    ],
                                    "color": "#54cb79",
                                    "destacado": null
                                }
                            },
                            {
                                "flatData": {
                                    "nombre": "Plan PYME",
                                    "precioMes": 120900,
                                    "caracteristicas": [
                                        {
                                            "titulo": "Contabilidad, Facturación, Inventario y Cotizaciones"
                                        },
                                        {
                                            "titulo": "Nómina Electrónica y Documento Soporte incluidos"
                                        },
                                        {
                                            "titulo": "Hasta 120 documentos electrónicos"
                                        },
                                        {
                                            "titulo": "Hasta 200 millones de ingresos"
                                        },
                                        {
                                            "titulo": "Hasta 5 usuarios"
                                        },
                                        {
                                            "titulo": "Hasta 2 empresas"
                                        }
                                    ],
                                    "color": "#4a90e2",
                                    "destacado": true
                                }
                            },
                            {
                                "flatData": {
                                    "nombre": "Plan Empresarial",
                                    "precioMes": 220900,
                                    "caracteristicas": [
                                        {
                                            "titulo": "Contabilidad, Facturación, Inventario y Cotizaciones"
                                        },
                                        {
                                            "titulo": "Nómina Electrónica y Documento Soporte incluidos"
                                        },
                                        {
                                            "titulo": "Hasta 450 documentos electrónicos"
                                        },
                                        {
                                            "titulo": "Hasta 500 millones de ingresos"
                                        },
                                        {
                                            "titulo": "Hasta 10 usuarios"
                                        },
                                        {
                                            "titulo": "Hasta 5 empresas"
                                        }
                                    ],
                                    "color": "#e0b861",
                                    "destacado": null
                                }
                            },
                            {
                                "flatData": {
                                    "nombre": "Plan Full Empresarial",
                                    "precioMes": -1,
                                    "caracteristicas": [
                                        {
                                            "titulo": "Acceso a todos los módulos"
                                        },
                                        {
                                            "titulo": "Documentos electrónicos ilimitados"
                                        },
                                        {
                                            "titulo": "Ingresos ilimitados"
                                        },
                                        {
                                            "titulo": "Usuarios ilimitados"
                                        },
                                        {
                                            "titulo": "Empresas ilimitadas"
                                        }
                                    ],
                                    "color": "#859ccb",
                                    "destacado": null
                                }
                            }
                        ]
                    }
                }
            },
            "extensions": {
                "tracing": {
                    "version": 1,
                    "startTime": "2023-02-28T16:14:30.2526025Z",
                    "endTime": "2023-02-28T16:14:30.2588726Z",
                    "duration": 6270100,
                    "parsing": {
                        "startOffset": 600,
                        "duration": 13200
                    },
                    "validation": {
                        "startOffset": 14400,
                        "duration": 255100
                    },
                    "execution": {
                        "resolvers": []
                    }
                }
            }
        }
    },
]