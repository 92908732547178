import React, { Component } from "react";
// import logoDevCloud from '../logo.svg';

export class Footer extends Component {
    render() {
        return (
            <footer className="footer footer-bar mt-4 bg-light">
                <div className="container text-center">
                    <div className="row align-items-center">
                        <div className="col-sm-6">
                            <div className="text-sm-left">
                                <p className="mb-0">SAMIT SISTEMAS CORPORATIVOS S.A.S.</p>
                                <a href="/Privacy">
                                    Política de Privacidad
                                </a>
                            </div>
                        </div>

                        <div className="col-sm-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <ul className="list-unstyled social-icon social text-sm-right mb-0">
                                <li className="list-inline-item" >
                                    <a href="https://wa.me/573173791261" target="_blank" rel="noopener noreferrer" className="text-reset rounded whatsapp">
                                        <i className="mdi icon-sm mdi-whatsapp" >
                                        </i>
                                    </a>
                                </li>
                                <span>{" "}</span>
                                <li className="list-inline-item">
                                    <a href="https://www.facebook.com/SAMITSQL" target="_blank" rel="noopener noreferrer" className="text-reset rounded facebook">
                                        <i className="mdi icon-sm mdi-facebook">
                                        </i>
                                    </a>
                                </li>
                                <span>{" "}</span>
                                <li className="list-inline-item">
                                    <a href="https://twitter.com/samit_sql" target="_blank" rel="noopener noreferrer" className="text-reset rounded twitter">
                                        <i className="mdi icon-sm mdi-twitter">
                                        </i>
                                    </a>
                                </li>
                                <span>{" "}</span>
                                <li className="list-inline-item">
                                    <a href="https://www.instagram.com/samitflorencia/" target="_blank" rel="noopener noreferrer" className="text-reset rounded instagram">
                                        <i className="mdi icon-sm mdi-instagram">
                                        </i>
                                    </a>
                                </li>
                                <span>{" "}</span>
                                <li className="list-inline-item">
                                    <a href="https://www.youtube.com/channel/UClldKEtHkLEQtGH8SbRxAXg" target="_blank" rel="noopener noreferrer" className="text-reset rounded youtube">
                                        <i className="mdi icon-sm mdi-youtube">
                                        </i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;