import React, { Component } from "react";

export class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div id="contact">
                <section >
                    <div className="container mb-5" >
                        {/* <div className="row justify-content-center">
                            <div className="col-12">
                                <div className="section-title mb-4 pb-2">
                                    <h4 className="title mb-4">Contactanos</h4>
                                    <p className="text-muted para-desc mb-0">Start working with <span className="text-primary font-weight-bold">Landrick</span> that can provide everything you need to generate awareness, drive traffic, connect.</p>
                                </div>
                            </div>
                        </div> */}

                        <div className="row">
                            <div className="col-lg-4 col-md-6 mt-4 pt-2">
                                <div className="card border-0">
                                    <div className="card-body py-1">
                                        <h4 className="card-title mt-100 mt-60">Contactanos</h4>
                                        <div className="custom-form mt-3">
                                            <div id="message"></div>
                                            <form method="post" action="php/contact.php" name="contact-form" id="contact-form">
                                                <div className="row">
                                                    <div className="col-md-12">

                                                        <p className="mt-0 mb-0" style={{ fontWeight: '500' }}>Whatsapp</p>
                                                        <a href="https://wa.me/573173791261" target='_blank' rel="noreferrer" style={{ color: 'green' }} className="text-foot">
                                                            <i className="mdi mdi-whatsapp mr-1"></i>3173791261
                                                        </a>

                                                        <p className="mt-4 mb-0" style={{ fontWeight: '500' }}>Numeros de Telefono</p>
                                                        <a href="tel:3115695960" className="text-foot">
                                                            <i className="mdi mdi-phone mr-1"></i>3115695960
                                                        </a>
                                                        <br />
                                                        <a href="tel:3173791261" className="text-foot">
                                                            <i className="mdi mdi-phone mr-1"></i>3173791261
                                                        </a>

                                                        <p className="mt-4 mb-0" style={{ fontWeight: '500' }}>Correos electronicos</p>
                                                        <span className="text-foot">
                                                            <i className="mdi mdi-gmail mr-1"></i>contacto@samit.com.co
                                                        </span>
                                                        <br />
                                                        <span className="text-foot">
                                                            <i className="mdi mdi-gmail mr-1"></i>soporte@samit.com.co
                                                        </span>

                                                        <p className="mt-4 mb-0" style={{ fontWeight: '500' }}>Direccion</p>
                                                        <span className="text-foot">
                                                            Carrera 5 #12-68 Barrio Porvenir
                                                            <br />
                                                            Florencia - Caqueta
                                                        </span>

                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-8 col-md-6 pl-md-3 pr-md-3 mt-4 pt-2">
                                <div className="card border-1 shadow rounded mt-100 mt-60">
                                    <div className="map map-height-two map-gray">
                                        <iframe title="googlemap" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15952.91884764079!2d-75.6044165!3d1.6169028!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xdcb2504586226df8!2sSAMIT%20Sistemas%20Corporativos!5e0!3m2!1ses-419!2sco!4v1627399684723!5m2!1ses-419!2sco" style={{ border: 0 }} className="rounded" allowFullScreen=""></iframe>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default Contact;
