import React, { Component } from "react";

export class Features extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div id="features">
                <div className="position-relative">
                    <div className="shape overflow-hidden text-light">
                        <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
                        </svg>
                    </div>
                </div>
                <section className="section bg-light mt-lg-100 mt-md-100">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-7 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <div className="section-title ml-lg-5">
                                    <h4 className="title mb-4">Contabilidad y Control Financiero</h4>
                                    <p className="text-dark">
                                        Nuestro Software permite llevar procesos contables con gran confianza, consulta inmediata de información contable a cualquier nivel.
                                    </p>
                                    <ul className="list-unstyled text-dark" style={{ fontSize: 14 }}>
                                        <li className="mb-0"><span className="text-primary h5 mr-2"><i className="mdi mdi-check"></i></span>
                                            Cuentas por Cobrar y Pagar con Mutiples tipos de informes
                                        </li>
                                        <li className="mb-0"><span className="text-primary h5 mr-2"><i className="mdi mdi-check"></i></span>
                                            Manejo por Centros de Costos y grupos de Centros de Costos
                                        </li>
                                        <li className="mb-0"><span className="text-primary h5 mr-2"><i className="mdi mdi-check"></i></span>
                                            Balances de Prueba o Comprobacion con Auxiliares dinamicos de cuentas contables
                                        </li>
                                        <li className="mb-0"><span className="text-primary h5 mr-2"><i className="mdi mdi-check"></i></span>
                                            Controles de (Tercero, Retenciones, Iva, y Controles Personalizados) en Cuentas Contables
                                        </li>
                                        <li className="mb-0"><span className="text-primary h5 mr-2"><i className="mdi mdi-check"></i></span>
                                            Todos los estados financieros (Balances,  Estado de Resultados, Libros Oficiales, Notas a los estados Fiancieros, etc.)
                                        </li>
                                        <li className="mb-0"><span className="text-primary h5 mr-2"><i className="mdi mdi-check"></i></span>
                                            Permite Traslados entre Oficinas o Sucursales
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-5 col-md-6">
                                <img src="images/features/1.webp" className="img-fluid" alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="container mt-60">
                        <div className="row align-items-center">
                            <div className="col-lg-5 col-md-6 order-2 order-md-1">
                                <img src="images/features/2.webp" className="img-fluid" alt="" />
                            </div>

                            <div className="col-lg-7 col-md-6 order-1 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <div className="section-title">
                                    <h4 className="title mb-4">Facturación Electrónica y Control de Inventarios</h4>
                                    <p className="text-dark">
                                        Administra y controla todos las facturas de tus Clientes y Proveedores, control y seguimiento a todos sus productos.
                                    </p>
                                    <ul className="list-unstyled text-dark" style={{ fontSize: 14 }}>
                                        <li className="mb-0"><span className="text-primary h5 mr-2"><i className="mdi mdi-check"></i></span>
                                            Multiples Tipos de Productos y Servicios
                                        </li>
                                        <li className="mb-0"><span className="text-primary h5 mr-2"><i className="mdi mdi-check"></i></span>
                                            Manejo de Multiples Bodegas o sus ubicaciones
                                        </li>
                                        <li className="mb-0"><span className="text-primary h5 mr-2"><i className="mdi mdi-check"></i></span>
                                            Controla Productos por Seriales, Por Lotes y Fechas de Vencimiento, De manera Individualizada
                                        </li>
                                        <li className="mb-0"><span className="text-primary h5 mr-2"><i className="mdi mdi-check"></i></span>
                                            Manejo de Cotizaciones, Remisiones, Ordenes y Pedidos
                                        </li>
                                        <li className="mb-0"><span className="text-primary h5 mr-2"><i className="mdi mdi-check"></i></span>
                                            Permite Traslados de inventario entre Bodegas y Oficinas o Sucursales
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container mt-60">
                        <div className="row align-items-center">
                            <div className="col-lg-7 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <div className="section-title ml-lg-5">
                                    <h4 className="title mb-4">Administración y Seguridad</h4>
                                    <p className="text-dark">
                                        Informes de Auditoría de Documentos, Informe de cualquier inconformidad con el balance, Claves de Acceso, definición de usuarios con diferentes atributos.
                                    </p>
                                    <ul className="list-unstyled text-dark" style={{ fontSize: 14 }}>
                                        <li className="mb-0"><span className="text-primary h5 mr-2"><i className="mdi mdi-check"></i></span>
                                            Control seguridad por Roles de usuarios
                                        </li>
                                        <li className="mb-0"><span className="text-primary h5 mr-2"><i className="mdi mdi-check"></i></span>
                                            Control de Ingreso por Empresa y Oficinas
                                        </li>
                                        <li className="mb-0"><span className="text-primary h5 mr-2"><i className="mdi mdi-check"></i></span>
                                            Control por Documento (Trazabilidad y Auditoria)
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-5 col-md-6">
                                <img src="images/features/3.webp" className="img-fluid" alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="container mt-60">
                        <br></br>
                    </div>

                </section>
                <div className="position-relative">
                    <div className="shape overflow-hidden text-white">
                        <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
                        </svg>
                    </div>
                </div>
            </div>
        );
    }
}

export default Features;
