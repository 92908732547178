import React, { Component } from "react";

export class Hero extends Component {
    constructor(props) {
        super(props);
        this.state = {
            urlImage: '/bg.jpg'
        };
    }

    render() {
        return (
            <div id="home">
                <section className="bg-home d-flex align-items-center"
                    style={{ height: 'auto' }}
                >
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-12 text-center mt-0 mt-md-5 pt-0 pt-md-5">
                                <div className="title-heading mt-mb-60">
                                    <h1 className="heading mb-3">
                                        Software Administrativo, Contable y Financiero
                                    </h1>
                                    <p className="para-desc mx-auto text-muted">
                                        El software más completo de gestión administrativa y financiera del mercado, integrando contabilidad y facturación con todos los procesos de su empresa.
                                    </p>
                                    <ul className="list-unstyled mx-auto text-muted" style={{ fontSize: 14 }}>
                                        <li className="mb-0"><span className="text-primary h5 mr-2"><i className="mdi mdi-check"></i></span>
                                            Informes por Oficinas o Surusales de Manera Individual o Consolidada
                                        </li>
                                        <li className="mb-0"><span className="text-primary h5 mr-2"><i className="mdi mdi-check"></i></span>
                                            Alta Seguridad de su informacion, Controlada por Usuarios y Oficinas
                                        </li>
                                        <li className="mb-0"><span className="text-primary h5 mr-2"><i className="mdi mdi-check"></i></span>
                                            Informe de alta calidad en todos sus Módulos
                                        </li>
                                    </ul>
                                    <div className="mt-4 pt-2">
                                        <a href="#contact" className="btn btn-primary">
                                            Iniciar Prueba Gratuita <i className="mdi mdi-chevron-right"></i>
                                        </a>
                                    </div>
                                </div>

                                <div className="home-dashboard">
                                    <img src="images/laptop1.png" alt="" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default Hero;
