import React, { Component } from "react";

export class Download extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
                <section >
                    <div className="container mt-100 mt-60">

                        <div className="row justify-content-center">
                            <div className="col-12 text-center">
                                <div className="section-title mb-4 pb-2 mt-60">
                                    <h4 className="title mb-4">Zona de Descargas</h4>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xl-12 col-md-12 mb-4">
                                <div className="card border-left-dark shadow h-100 py-0">
                                    <div className="card-body">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col-lg-12">
                                                <div className="text-xs font-weight-bold text-dark text-uppercase mb-2">
                                                    SamitCloud
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row no-gutters align-items-center">
                                            <div className="col-lg-9 col-xs-12">
                                                <div className="h4 mb-0 font-weight-bold text-gray-800">
                                                    <img style={{ height: 32, width: 32, marginBottom: -5 }} src="./images/cloud/icon64.png" alt="SAMIT" />
                                                    <span className="ml-2">
                                                        Instalador SamitCloud para Windows 7, 8, 10 y 11.
                                                    </span>
                                                </div>
                                                <p className="text-muted">
                                                    Si quieres hacer pruebas de SamitCloud puedes ingresar con los siguientes datos.
                                                    <br />
                                                    Usuario: pruebas@gmail.com
                                                    <br />
                                                    Contraseña: 1234
                                                </p>
                                            </div>
                                            <div className="col-lg-3 col-xs-12">
                                                <a href={"https://samitcloud.ddns.net/setup/Instalador_SamitCloud.exe"} target="_blank" rel="noreferrer" className="btn btn-primary btn-block font-weight-bold">
                                                    <i className="mdi mdi-download"></i>
                                                    {" Descargar Instalador"}
                                                </a>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xl-12 col-md-12 mb-4">
                                <div className="card border-left-dark shadow h-100 py-0">
                                    <div className="card-body">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col-lg-12">
                                                <div className="text-xs font-weight-bold text-dark text-uppercase mb-2">
                                                    SAMITsql .NET
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row no-gutters align-items-center">
                                            <div className="col-lg-9 col-xs-12">
                                                <div className="h4 mb-0 font-weight-bold text-gray-800">
                                                    <img style={{ height: 32, width: 32, marginTop: -5 }} src="./images/net/icon32.png" alt="SAMIT" />
                                                    <span className="ml-2">
                                                        Instalador SAMITsql .NET para Windows 7, 8, 10 y 11.
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-xs-12">
                                                <a href={"https://samitcloud.ddns.net/setup/Instalador_SamitSQL.exe"} target="_blank" rel="noreferrer" className="btn btn-primary btn-block font-weight-bold">
                                                    <i className="mdi mdi-download"></i>
                                                    {" Descargar Instalador"}
                                                </a>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xl-12 col-md-12 mb-4">
                                <div className="card border-left-dark shadow h-100 py-0">
                                    <div className="card-body">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col-lg-12">
                                                <div className="text-xs font-weight-bold text-dark text-uppercase mb-2">
                                                    Consultor de Precios
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row no-gutters align-items-center">
                                            <div className="col-lg-9 col-xs-12">
                                                <div className="h4 mb-0 font-weight-bold text-gray-800">
                                                    <img style={{ height: 32, width: 32, marginTop: -5 }} src="./images/consultor/icon.png" alt="Samit Consultor Precios" />
                                                    <span className="ml-2">
                                                        Aplicacion de consulta de precios por codigos de barras.
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-xs-12">
                                                <a href={"https://samitcloud.ddns.net/setup/Instalador_SamitConsultaPrecios.exe"} target="_blank" rel="noreferrer" className="btn btn-secondary btn-block font-weight-bold">
                                                    <i className="mdi mdi-download"></i>
                                                    {" Descargar Instalador"}
                                                </a>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xl-12 col-md-12 mb-4">
                                <div className="card border-left-dark shadow h-100 py-0">
                                    <div className="card-body">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col-lg-12">
                                                <div className="text-xs font-weight-bold text-dark text-uppercase mb-2">
                                                    MiSamit (Solicitud soporte)
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row no-gutters align-items-center">
                                            <div className="col-lg-9 col-xs-12">
                                                <div className="h4 mb-0 font-weight-bold text-gray-800">
                                                    <img style={{ height: 32, width: 32, marginTop: -5 }} src="./images/misamit/icon.png" alt="SAMIT" />
                                                    <span className="ml-2">
                                                        Aplicacion MiSAMIT para Android.
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-xs-12">
                                                <a href={"https://play.google.com/store/apps/details?id=com.samitsql.misamit&hl=es&gl=US"} target="_blank" rel="noreferrer" className="btn btn-secondary btn-block font-weight-bold">
                                                    <i className="mdi mdi-google-play"></i>
                                                    {" Ver en Google Play Store"}
                                                </a>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="row justify-content-center">
                            <div className="col-12">
                                <div className="mt-4 pt-2 mt-lg-100 ml-2">
                                    <h5 className="title mb-4">
                                        Otras Descargas - Complementos
                                    </h5>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xl-12 col-md-12 mb-4">
                                <div className="card border-left-dark shadow h-100 py-0">
                                    <div className="card-body">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col-lg-12">
                                                <div className="text-xs font-weight-bold text-dark text-uppercase mb-2">
                                                    Anydesk
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row no-gutters align-items-center">
                                            <div className="col-lg-9 col-xs-12">
                                                <div className="h4 mb-0 font-weight-bold text-gray-800">
                                                    <img style={{ height: 32, width: 32, marginTop: -5 }} src="./images/any/icon.png" alt="SAMIT" />
                                                    <span className="ml-2">
                                                        Ejecutable de AnyDesk - Asistencia Remota
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-xs-12">
                                                <a href={"https://download.anydesk.com/AnyDesk.exe"} target="_blank" rel="noreferrer" className="btn btn-outline-secondary btn-block font-weight-bold">
                                                    <i className="mdi mdi-download"></i>
                                                    {" Descargar AnyDesk"}
                                                </a>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xl-12 col-md-12 mb-4">
                                <div className="card border-left-dark shadow h-100 py-0">
                                    <div className="card-body">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col-lg-12">
                                                <div className="text-xs font-weight-bold text-dark text-uppercase mb-2">
                                                    .NET Framework 4.6
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row no-gutters align-items-center">
                                            <div className="col-lg-9 col-xs-12">
                                                <div className="h4 mb-0 font-weight-bold text-gray-800">
                                                    <img style={{ height: 32, width: 32, marginTop: -5 }} src="./images/net/iconnet.png" alt="SAMIT" />
                                                    <span className="ml-2">
                                                        Instalador (WEB) de .NET Framework 4.6
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-xs-12">
                                                <a href={"https://download.microsoft.com/download/1/4/A/14A6C422-0D3C-4811-A31F-5EF91A83C368/NDP46-KB3045560-Web.exe"} target="_blank" rel="noreferrer" className="btn btn-outline-secondary btn-block font-weight-bold">
                                                    <i className="mdi mdi-download"></i>
                                                    {" Descargar Instalador"}
                                                </a>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xl-12 col-md-12 mb-4">
                                <div className="card border-left-dark shadow h-100 py-0">
                                    <div className="card-body">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col-lg-12">
                                                <div className="text-xs font-weight-bold text-dark text-uppercase mb-2">
                                                    SQL Server Management Studio
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row no-gutters align-items-center">
                                            <div className="col-lg-9 col-xs-12">
                                                <div className="h4 mb-0 font-weight-bold text-gray-800">
                                                    <img style={{ height: 32, width: 32, marginTop: -5 }} src="./images/sql/icon.png" alt="SAMIT" />
                                                    <span className="ml-2">
                                                        Instalador de SQL Server Management Studio
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-xs-12">
                                                <a href={"https://aka.ms/ssmsfullsetup"} target="_blank" rel="noreferrer" className="btn btn-outline-secondary btn-block font-weight-bold">
                                                    <i className="mdi mdi-download"></i>
                                                    {" Descargar Instalador"}
                                                </a>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xl-12 col-md-12 mb-4">
                                <div className="card border-left-dark shadow h-100 py-0">
                                    <div className="card-body">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col-lg-12">
                                                <div className="text-xs font-weight-bold text-dark text-uppercase mb-2">
                                                    SQL Server 2017 Express
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row no-gutters align-items-center">
                                            <div className="col-lg-9 col-xs-12">
                                                <div className="h4 mb-0 font-weight-bold text-gray-800">
                                                    <img style={{ height: 32, width: 32, marginTop: -5 }} src="./images/sql/iconserver.png" alt="SAMIT" />
                                                    <span className="ml-2">
                                                        Instalador de SQL Server 2017 Express
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-xs-12">
                                                <a href={"https://samitcloud.ddns.net/setup/SQL_2017_CORE.exe"} target="_blank" rel="noreferrer" className="btn btn-outline-secondary btn-block font-weight-bold">
                                                    <i className="mdi mdi-download"></i>
                                                    {" Descargar Instalador"}
                                                </a>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xl-12 col-md-12 mb-4">
                                <div className="card border-left-dark shadow h-100 py-0">
                                    <div className="card-body">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col-lg-12">
                                                <div className="text-xs font-weight-bold text-dark text-uppercase mb-2">
                                                    POS Sync
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row no-gutters align-items-center">
                                            <div className="col-lg-9 col-xs-12">
                                                <div className="h4 mb-0 font-weight-bold text-gray-800">
                                                    <img style={{ height: 32, width: 32, marginTop: -5 }} src="./images/sync/icon.png" alt="SAMIT" />
                                                    <span className="ml-2">
                                                        Sincronizador del POS Offline
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-xs-12">
                                                <a href={"https://samitcloud.ddns.net/setup/Instalador_SamitPosSync.exe"} target="_blank" rel="noreferrer" className="btn btn-outline-secondary btn-block font-weight-bold">
                                                    <i className="mdi mdi-download"></i>
                                                    {" Descargar PosSync"}
                                                </a>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xl-12 col-md-12 mb-4">
                                <div className="card border-left-dark shadow h-100 py-0">
                                    <div className="card-body">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col-lg-12">
                                                <div className="text-xs font-weight-bold text-dark text-uppercase mb-2">
                                                    POS Offline
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row no-gutters align-items-center">
                                            <div className="col-lg-9 col-xs-12">
                                                <div className="h4 mb-0 font-weight-bold text-gray-800">
                                                    <img style={{ height: 32, width: 32, marginTop: -5 }} src="./images/posOffline/icon.png" alt="SAMIT" />
                                                    <span className="ml-2">
                                                        Sistema de facturación POS modo Offline
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-xs-12">
                                                <a href={"https://samitcloud.ddns.net/setup/Instalador_SamitPosOffline.rar"} target="_blank" rel="noreferrer" className="btn btn-outline-secondary btn-block font-weight-bold">
                                                    <i className="mdi mdi-download"></i>
                                                    {" Descargar PosOffline"}
                                                </a>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </div>
        );
    }
}

export default Download;
