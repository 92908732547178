import React, { Component } from "react";

export class Videos extends Component {
    constructor(props) {
        super(props);
        var orderVideos = props.data.sort(function (a, b) {
            if (a.flatData.modulo > b.flatData.modulo) {
                return 1;
            }
            if (a.flatData.modulo < b.flatData.modulo) {
                return -1;
            }
            return 0;
        }).sort(function (a, b) {
            if (a.flatData.versionApp < b.flatData.versionApp) {
                return 1;
            }
            if (a.flatData.versionApp > b.flatData.versionApp) {
                return -1;
            }
            return 0;
        });

        this.state = {
            appVersion: "",
            versiones: ["SamitCloud", "SAMITsql"],
            modulo: "",
            modulos: [],
            videosFull: orderVideos,
            videosFiltered: orderVideos,
        };
    }

    componentDidMount() {
        this.loadFromUrl();
    }

    loadFromUrl() {
        const queryString = require('query-string');
        const parsed = queryString.parse(document.location.search);

        if ((parsed.app ?? "") !== "") {
            this.selectVersion(parsed.app, () => {
                if ((parsed.modulo ?? "") !== "") {
                    this.selectModulo(parsed.modulo);
                }
            });
        }
    }

    selectVersion(version, callback) {
        this.setState({
            appVersion: version,
            videosFiltered: this.state.videosFull.filter(x =>
                (version === "" || x.flatData.versionApp === version)
            ),
            modulo: ""
        }, () => {
            var modulos = [];
            this.state.videosFiltered.forEach(element => {
                var modulo = element.flatData.modulo;
                var index = modulos.findIndex(x => x.name === modulo);
                if (index < 0) {
                    modulos.push({
                        name: modulo,
                        cant: 1
                    });
                } else {
                    modulos[index].cant += 1;
                }
            });
            this.setState({
                modulos: modulos
            }, () => {
                this.updateUrl();
                if (callback)
                    callback();
            });
        });
    }

    selectModulo(modulo) {
        this.setState({
            modulo: modulo,
            videosFiltered: this.state.videosFull.filter(x =>
                (this.state.appVersion === "" || x.flatData.versionApp === this.state.appVersion)
                && (modulo === "" || x.flatData.modulo === modulo)
            ),
        }, () => {
            this.updateUrl();
        })
    }

    updateUrl() {
        var urlPart = "/Videos";
        urlPart += "?app=" + (this.state.appVersion ?? "");
        if (this.state.modulo !== "")
            urlPart += "&modulo=" + (this.state.modulo ?? "all");
        window.history.replaceState({}, "", urlPart);
    }

    render() {
        return (
            <div>
                <section >
                    <div className="container mt-100 mt-60">

                        <div className="row justify-content-center">
                            <div className="col-12 text-center">
                                <div className="section-title mb-2 pb-2 mt-60">
                                    <h4 className="title mb-2">Videos Tutoriales</h4>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <h5 className="title">Version SAMIT:</h5>
                        </div>
                        <div className="row mb-2 align-items-center">
                            {
                                this.state.versiones.map((item, index) => {
                                    return (
                                        <button key={index} type="button" className={"m-1 btn " + ((this.state.appVersion === item) ? "btn-success" : "btn-outline-secondary")} onClick={() => {
                                            this.selectVersion(item);
                                        }}>
                                            {
                                                (item === "SamitCloud") ?
                                                    <img alt="SamitCloud" className="mr-2 p-0" src={"./images/cloud/icon64.png"} height={20}>
                                                    </img>
                                                    : null
                                            }
                                            {item}
                                        </button>
                                    )
                                })
                            }
                        </div>

                        {
                            ((this.state.modulos.length > 0)
                                ? <>
                                    <div className="row">
                                        <h5 className="title">Modulo:</h5>
                                    </div>
                                    <div className="row mb-4 align-items-center">
                                        {
                                            this.state.modulos.map((item, index) => {
                                                return (
                                                    <button key={index} type="button" className={"m-1 btn " + ((this.state.modulo === item.name) ? "btn-success" : "btn-outline-secondary")} onClick={() => {
                                                        this.selectModulo(item.name);
                                                    }}>
                                                        {item.name}
                                                        {/* <span class={"badge ml-1 p-1 " + ((this.state.modulo === item.name) ? "badge-light" : "")}>
                                                            {"(" + item.cant + ")"}
                                                        </span> */}
                                                    </button>
                                                )
                                            })
                                        }
                                    </div>
                                </>
                                : <div className="row mb-4"></div>
                            )
                        }

                        <div className="row align-items-center justify-content-center">
                            {
                                this.state.videosFiltered.map((item, index) => {
                                    var video = item.flatData;
                                    return (
                                        <div key={index} className="col-xl-12 col-12 mb-4 p-0">
                                            <div className="card border-left-dark shadow py-0">
                                                <div className="card-body">
                                                    <div className="top-note-wrapper">
                                                        <span className="top-note h5" style={{
                                                            backgroundColor: (video.versionApp === "SamitCloud") ? "rgb(74, 144, 226)" : "rgb(116, 132, 150)",
                                                            color: 'white',
                                                            fontSize: 16,
                                                            fontWeight: '700',
                                                            padding: 4,
                                                            paddingInline: 10,
                                                        }}>
                                                            {
                                                                (video.versionApp === "SamitCloud") ?
                                                                    <img alt="SamitCloud" className="mr-2 mt-1" src={"./images/cloud/icon64.png"} height={25}>
                                                                    </img>
                                                                    : null
                                                            }
                                                            {video.versionApp}
                                                        </span>
                                                    </div>

                                                    <div className="row no-gutters align-items-center mt-3">
                                                        <div className="col-12">
                                                            <div className="h4 mb-0 font-weight-bold text-gray-800">
                                                                <span className="ml-0">
                                                                    {
                                                                        video.titulo
                                                                    }
                                                                </span>
                                                            </div>
                                                            <span className="badge badge-secondary">
                                                                {
                                                                    video.modulo
                                                                }
                                                            </span>
                                                            <p className="text-muted">
                                                                {
                                                                    (video.descripcion) ?
                                                                        video.descripcion.split('\n').map((value) => {
                                                                            return <>{value}<br /></>;
                                                                        })
                                                                        : "Sin descripción"
                                                                }
                                                            </p>
                                                        </div>
                                                        <div className="watch-video col-12 d-flex flex-row-reverse">
                                                            <a href={video.url} className="video-play-icon watch text-dark">
                                                                <i className="mdi mdi-play play-icon-circle text-center d-inline-block mr-2 rounded-circle title-dark text-white play play-iconbar"></i> MIRAR VIDEO
                                                            </a>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>

                    </div>
                </section >
            </div >
        );
    }
}

export default Videos;
