const axios = require("axios")
const token = ''

const queryCMS = query => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios.post(`https://samitcms.ddns.net/api/content/samit-landing/graphql`, {
                query: `
                    query {
                        ${query}
                    }
                  `
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            resolve(result.data.data)
        } catch (error) {
            console.error('Failed to query CMS', error)
            reject(error)
        }
    })
}


export {
    queryCMS
}