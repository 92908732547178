import React, { Component } from "react";

export class Privacy extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
<div>
    <section>
        <div className="container mt-100 mt-60">
            <div className="row justify-content-center">
                <div className="col-12 text-center">
                    <div className="section-title mb-4 pb-2 mt-60">
                        <h4 className="title mb-4">Política de Privacidad</h4>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-xl-12 col-md-12 mb-4">
                    <p>
                        En SamitSQL, nos comprometemos a proteger la privacidad de nuestros usuarios y a garantizar la seguridad de su información personal. Esta Política de Privacidad describe cómo utilizamos, almacenamos y protegemos la información que nos proporcionas al interactuar con nuestra aplicación.
                    </p>

                    <h5>1. Uso de la Información</h5>
                    <p>
                        1.1 Utilizamos la información proporcionada por el usuario para garantizar el funcionamiento continuo y eficiente de nuestra aplicación, así como para mejorar y personalizar la experiencia del usuario según sus preferencias.
                    </p>
                    <p>
                        1.2 Podemos utilizar tu información de contacto, como tu dirección de correo electrónico o número de teléfono, para enviarte comunicaciones periódicas, incluyendo actualizaciones sobre la aplicación, boletines informativos y promociones. Siempre tendrás la opción de darte de baja de estas comunicaciones.
                    </p>
                    <p>
                        1.3 No compartimos, vendemos ni alquilamos tu información personal a terceros, a menos que sea necesario para brindar servicios específicos que hayas solicitado o para cumplir con requisitos legales.
                    </p>

                    <h5>2. Seguridad de la Información</h5>
                    <p>
                        2.1 Implementamos medidas de seguridad apropiadas para proteger tu información personal contra accesos no autorizados, alteraciones, divulgaciones o destrucciones.
                    </p>

                    <h5>3. Protección de datos y cumplimiento de la legislación</h5>
                    <p>
                        3.1 Eres responsable de emitir y garantizar todos los avisos correspondientes, así como de obtener todos los permisos y consentimientos necesarios para recopilar, usar y compartir el contenido y la información de las personas, lo que incluye mantener una política de privacidad publicada, así como cumplir con la legislación aplicable.
                    </p>
                    <p>
                        3.2 No uses datos que te hayamos proporcionado sobre una persona con la que te comunicas por WhatsApp, aparte del contenido de las conversaciones, para ningún fin, excepto que sea razonablemente necesario para intercambiar mensajes con esa persona.
                    </p>
                    <p>
                        3.3 No compartas ni pidas a las personas que compartan números completos de tarjetas de pago individuales, números de cuentas bancarias, números de documentos de identificación personales ni otros identificadores confidenciales.
                    </p>
                    <p>
                        3.4 No utilices WhatsApp para ofrecer telemedicina ni para enviar o solicitar información relativa a la salud si las regulaciones aplicables prohíben la distribución de este tipo de datos en sistemas que no cumplen con requisitos más estrictos para tratar dicha información sobre la salud.
                    </p>
                    <p>
                        3.5 No puedes reenviar ni, de otro modo, compartir información del chat de un cliente con otro cliente.
                    </p>

                    <h5>4. Cambios a la Política de Privacidad</h5>
                    <p>
                        4.1 Nos reservamos el derecho de modificar esta Política de Privacidad en cualquier momento. Los cambios entrarán en vigor inmediatamente después de su publicación en esta página.
                    </p>
                    <p>
                        4.2 Es tu responsabilidad revisar periódicamente esta Política de Privacidad para estar al tanto de cualquier actualización o cambio.
                    </p>

                    <h5>5. Contacto</h5>
                    <p>
                        5.1 Si tienes alguna pregunta, inquietud o solicitud relacionada con esta Política de Privacidad, no dudes en contactarnos en Soporte@samitsql.com.
                    </p>

                    <p>
                        Al utilizar nuestra aplicación, aceptas los términos y condiciones descritos en esta Política de Privacidad. Si no estás de acuerdo con esta política, te recomendamos que no utilices nuestra aplicación.
                    </p>
                </div>
            </div>
        </div>
    </section>
</div>
        );
    }
}

export default Privacy;
